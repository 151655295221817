import { Navigate, Route, Routes } from "react-router-dom";
import "../index.css";
import React from "react";
// import Visits from "./Visits";
import Users from "./Users";

const Dashboard = () => {
  return (
    <div style={{ height: "calc(100svh - 3rem)" }} className="w-full">
      <div className="w-full h-full bg-gray-100 flex px-2 pb-2">
        <div className="bg-white rounded-lg w-full overflow-hidden border">
          <Routes>
            <Route path="/" element={<Navigate to="/users" />} />
            {/* <Route path="/visits/*" element={<Visits />} /> */}
            <Route path="/users/*" element={<Users />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
