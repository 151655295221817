import "./App.css";
import { useContext } from "react";
import { AuthContext } from "./context/authContext";
import ScrollToTop from "./utils/scrollToTop";
import { AxiosInterceptor } from "./utils/authAxios";
import NavBar from "./components/NavBar";
import Login from "./pages/Login";
import NavBar2 from "./components/NavBar2";
import Dashboard from "./pages/Dashboard";

function App() {
  const { userState } = useContext(AuthContext);
  if (userState.isAuthenticated && userState.user.userId === 2)
    return (
      <AxiosInterceptor>
        <ScrollToTop />
        <NavBar2 />
        <div className="flex flex-col items-center w-full overflow-x-hidden text-gray-800">
          <Dashboard />
        </div>
      </AxiosInterceptor>
    );
  else
    return (
      <AxiosInterceptor>
        <ScrollToTop />
        <NavBar />
        <div className="flex flex-col items-center w-full overflow-x-hidden text-gray-800">
          <Login />
        </div>
      </AxiosInterceptor>
    );
}

export default App;
