import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <header className="w-full flex-shrink-0 flex items-center justify-center z-30 fixed top-0 h-20 p-2 bg-white">
      <div className="flex items-center font-medium w-full max-w-screen-xl h-full justify-between rounded-full px-5 lg:px-10 py-4 lg:py-0">
        <Link to="/">
          <div className="flex items-center space-x-2">
            <img
              src={"https://pardocs.s3.ap-northeast-2.amazonaws.com/landing/pardocs_logo.png"}
              alt=""
              className="h-7 md:h-7 w-32 object-contain"
              draggable={false}
            />
            <p className="text-sm text-gray-500">ADMIN</p>
          </div>
        </Link>
        <div className="flex flex-shrink-0 font-medium items-center text-gray-700">
          <Link className="flex-shrink-0" to={"https://www.pardocs.com"}>
            <button className="text-sm bg-gray-700 h-8 px-3.5 rounded-full text-white transition flex-shrink-0 hover:brightness-125">
              Go to ParDocs
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
