import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/authAxios";
import { HttpStatusCode } from "axios";
import { AuthContext } from "../context/authContext";
import { Input, Button } from "antd";

const Login = () => {
  const navigate = useNavigate();
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(false);
  const { updateAccessToken } = useContext(AuthContext);

  useEffect(() => {
    if (usernameValue.length < 1 || passwordValue.length < 1 || isLoading) {
      setLoginDisabled(true);
    } else {
      setLoginDisabled(false);
    }
  }, [isLoading, passwordValue.length, usernameValue.length]);

  const authenticate = useCallback(
    (data) => {
      setLoading(true);
      setErrorMessage("");
      axios
        .post(`/v1/auth/login`, data)
        .then((response) => {
          if (response.status === HttpStatusCode.Ok) {
            console.log("ASDASD", response.data)
            updateAccessToken(response.data.access_token).then(() => {
              navigate("/users");
            });
          } else {
            setErrorMessage("Something went wrong...");
          }
          setLoading(false);
        })
        .catch((error) => {
          const status = error.response ? error.response.status : 0;
          switch (status) {
            case 404:
              if (data.auth.auth_type !== "email") {
                axios
                  .post(`/v1/auth/register`, {
                    ...data,
                    user: {},
                  })
                  .then((registerResponse) => {
                    updateAccessToken(registerResponse.data.access_token).then(() => {
                      navigate("/users");
                    });
                  });
              } else {
                setErrorMessage("Wrong email or password");
              }
              break;
            case 403:
              setErrorMessage("Wrong email or password");
              break;
            case 422: // Commonly used for Unprocessable Entity
              setErrorMessage("Invalid email format");
              break;
            default:
              setErrorMessage("Something went wrong...");
          }
        })
        .finally(() => setLoading(false));
    },
    [updateAccessToken, navigate]
  );

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      authenticate({ auth: { auth_type: "email", user_email: usernameValue, user_password: passwordValue } });
    }
  };

  return (
    <div className="w-full flex flex-col items-center relative">
      <div
        style={{ minHeight: "calc(100vh)" }}
        className="w-full flex flex-col items-center justify-center relative bg-neutral-50 flex-shrink-0 py-36"
      >
        <div className="max-w-md px-4 w-full" onKeyDown={handleKeypress}>
          <div className="bg-white px-6 md:px-8 py-6 md:py-10 rounded-lg text-gray-700 shadow-lg">
            <h1 className="text-2xl font-bold">Log in</h1>

            <div className="bg-white rounded w-full mt-8 space-y-4">
              <Input
                placeholder="Email address"
                value={usernameValue}
                onChange={(e) => setUsernameValue(e.target.value)}
                className="h-11"
                type="email"
              />
              <Input.Password
                placeholder="Password"
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                className="h-11"
              />
            </div>

            {errorMessage && <p className="mt-2 text-xs text-red-500">{errorMessage}</p>}
            <Button
              type="primary"
              onClick={() =>
                authenticate({ auth: { auth_type: "email", user_email: usernameValue, user_password: passwordValue } })
              }
              disabled={loginDisabled}
              loading={isLoading}
              className={`mt-10 w-full h-10 text-base font-semibold px-24 border-none ${
                loginDisabled ? "bg-[#d9d9d9]" : "bg-blue-500"
              }`}
            >
              {isLoading ? "Loading..." : "Log in"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
