import React from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileButton from "./ProfileButton";

const NavBar2 = () => {
  const location = useLocation(); // Hook to get the current path
  const currentPath = location.pathname;

  const NavButton = ({ path, title }) => {
    return (
      <Link
        to={path}
        className={`${
          currentPath.includes(path) ? "text-gray-700 font-semibold" : " text-gray-500 hover:text-gray-700"
        } text-sm hidden md:flex hover:bg-gray-100 px-3 py-1.5 rounded-full group font-WantedSans transition`}
      >
        <button className="flex items-center space-x-1.5">
          <p>{title}</p>
        </button>
      </Link>
    );
  };

  return (
    <header className="w-full flex items-center justify-center z-30 h-12 bg-gray-100">
      <div className="flex items-center w-full h-full justify-between rounded-full px-3 py-4 lg:py-0">
        <Link to="/users">
          <div className="flex items-center space-x-2">
            <img
              src={"https://pardocs.s3.ap-northeast-2.amazonaws.com/landing/pardocs_logo.png"}
              alt=""
              className="h-6 object-contain"
              draggable={false}
            />
            <p className="text-sm text-gray-500 font-medium">ADMIN</p>
          </div>
        </Link>
        <div className="flex flex-shrink-0 items-center text-gray-700">
          <div className="flex items-center space-x-3">
            <NavButton path={"/users"} title={"Users"} />
            {/* <NavButton path={"/visits"} title={"Visits"} /> */}
            <ProfileButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar2;
