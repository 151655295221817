import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import axios from "../utils/authAxios";
import { Dropdown } from "antd";
import { HttpStatusCode } from "axios";

const ProfileButton = ({ mode = "light" }) => {
  const { userState, updateAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const userInfo = userState.user;

  function logout() {
    axios
      .post(`/v1/auth/logout`)
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          updateAccessToken(null).then();
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  }

  const items = [
    {
      label: <p className="py-2 text-gray-400 text-sm">{userState.user.userEmail}</p>,
      key: "0",
      disabled: true,
    },
    { type: "divider" },
    {
      label: <p className="py-2 text-red-500 text-sm">Log out</p>,
      key: "1",
      onClick: () => logout(),
    },
  ];

  return (
    <div className="flex-shrink-0">
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        className="w-full h-full flex items-center cursor-pointer"
      >
        <button
          className={`${
            mode === "dark" ? "hover:bg-gray-600" : "hover:bg-gray-100"
          } h-10 relative flex items-center space-x-2 px-3 py-2 transition`}
        >
          {userInfo?.userImg ? (
            <img
              src={userInfo.userImg}
              className="w-8 h-8 rounded-lg bg-gray-200 flex-shrink-0 overflow-hidden relative flex items-center justify-center object-cover border border-gray-300"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://pardocs.s3.ap-northeast-2.amazonaws.com/landing/default-profile.png";
              }}
              alt="Profile"
            />
          ) : (
            <div className="h-8 w-8 bg-white rounded-lg border flex-shrink-0 bg-gradient-to-br from-fuchsia-400 to-fuchsia-300 font-medium text-white flex items-center justify-center">
              {userInfo.userName.slice(0, 1)}
            </div>
          )}
        </button>
      </Dropdown>
    </div>
  );
};

export default ProfileButton;
