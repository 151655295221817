import React, { useEffect, useState } from "react";
import axios from "../utils/authAxios";
import { Table } from "antd";
import moment from "moment";

const Users = () => {
  const [userList, setUserList] = useState([]);
  const getUsers = () => {
    axios
      .get(`/v1/admin/users`, {
        params: {
          offset: 0,
          limit: 40,
        },
      })
      .then((response) => {
        setUserList(response.data);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getUsers();
    return () => {};
  }, []);

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: "ID",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Email",
      dataIndex: "user_email",
      key: "user_email",
    },
    {
      title: "Created",
      dataIndex: "user_created_at",
      key: "user_created_at",
      render: (item) => <div>{moment(item).format("MMM DD, HH:mm A")}</div>,
    },
    {
      title: "# of templates",
      dataIndex: "templates",
      key: "templates",
      render: (templates) => (
        <div className={`${!templates[0].template_id ? "text-gray-300" : "font-medium"}`}>
          {!templates[0].template_id ? 0 : templates?.length}
        </div>
      ),
    },
    {
      title: "# of uploads",
      dataIndex: "documents",
      key: "documents",
      render: (documents) => (
        <div className={`${documents.length === 0 ? "text-gray-300" : "font-medium"}`}>{documents?.length}</div>
      ),
    },
  ];

  return (
    <div style={{ height: "calc(100svh - 3.7rem)" }} className="overflow-y-auto">
      <Table
        size="middle"
        className="font-WantedSans"
        dataSource={userList}
        columns={columns}
        rowKey="user_id"
        pagination={{ position: ["topLeft"], pageSize: 20 }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="flex space-x-4 p-3">
              <div className="w-64 flex-shrink-0">
                <p className="text-xs font-medium mb-2">TEMPLATES</p>
                {record.templates[0].template_id &&
                  record.templates.map((item, index) => (
                    <div key={index} className="flex space-x-4">
                      <div>
                        • {item.template_id} ({item.document_type})
                      </div>
                    </div>
                  ))}
              </div>
              <div className="">
                <p className="text-xs font-medium mb-2">UPLOADS</p>
                <div className="flex flex-col">
                  {record.documents.map((item, index) => (
                    <a
                      href={`https://pardocs.s3.ap-northeast-2.amazonaws.com/${item}`}
                      key={index}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-600"
                    >
                      • https://pardocs.s3.ap-northeast-2.amazonaws.com/{item}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ),
        }}
      />
    </div>
  );
};

export default Users;
